<template>
  <Card>
    <template #content>
      <div style="display: flex; justify-content: end">
        <Button
          label="Add"
          icon="pi pi-plus"
          class="response-button"
          style="
            height: 2rem;
            margin: 0;
            padding-left: 12px;
            padding-right: 12px;
            font-size: small;
          "
          @click="openNew"
        />
      </div>
      <DataTable
        :value="this.creditCards"
        responsiveLayout="stack"
        breakpoint="1279px"
        class="p-datatable-sm custInquiry-ccTable"
        @row-click="openRow"
      >
        <template #empty> No credit cards to display. </template>
        <Column
          field="cardholder_name"
          header="Credit Card Name"
          style="min-width: 12rem"
        >
          <template #body="slotProps">
            {{ slotProps.data.cardholder_name }}
          </template>
        </Column>
        <Column
          field="credit_card_no"
          header="Credit Card Number"
          style="min-width: 12rem; text-align: end"
        >
          <template #body="slotProps">
            {{ formatCardNumber(slotProps.data.credit_card_id) }}
          </template>
        </Column>
        <Column
          field="credit_card_exp"
          header="Expiry Date"
          style="min-width: 16rem; text-align: center"
        >
          <template #body="slotProps">
            {{ formatExpDate(slotProps.data.credit_card_exp) }}
          </template>
        </Column>
        <Column
          :exportable="false"
          style="min-width: 8rem"
          class="align-items-center p-1"
        >
          <template #header>
            <Button
              label="Add"
              icon="pi pi-plus"
              class="text-sm h-2rem m-0 pl-3 pr-3"
              @click="openNew"
            />
          </template>
          <template #body="slotProps">
            <Button
              icon="pi pi-trash"
              title="Delete"
              class="ml-1"
              @click="confirmDeleteItem(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
  <Dialog
    v-model:visible="creditCardDialog"
    :style="{ width: '450px' }"
    header="Credit Card Details"
    :modal="true"
    :draggable="false"
    class="p-fluid"
  >
    <div v-if="this.displayIframe && !this.success">
      <iframe
        v-resize="{
          log: false,
          heightCalculationMethod: 'documentElementScroll',
        }"
        :src="getIframeUrl"
        class="w-full"
        :key="iframeKey"
        id="iframe"
        frameborder="0"
      >
      </iframe>
    </div>
    <div class="formgrid grid mb-3" v-if="showAddressInput && this.success">
      <div class="col-12 lg:col-6">
        <div class="p-inputgroup mt-2">
          <small
            class="p-inputgroup-addon min-w-min font-semibold general-label-color"
            :style="{ width: '80px' }"
          >
            Name
          </small>
          <InputText
            type="text"
            id="Name"
            v-model="creditCard.cardholder_name"
            class="text-sm"
            :disabled="inputDisabled"
          />
        </div>
        <small class="p-error" v-if="submitted && !creditCard.cardholder_name"
          >Name is required</small
        >
      </div>
      <div class="col-12 lg:col-6">
        <div class="p-inputgroup mt-2">
          <small
            class="p-inputgroup-addon min-w-min font-semibold general-label-color"
            :style="{ width: '80px' }"
          >
            Street
          </small>
          <InputText
            type="text"
            id="Street"
            v-model="creditCard.cc_addr"
            class="text-sm"
            :disabled="inputDisabled"
          />
        </div>
        <small class="p-error" v-if="submitted && !creditCard.cc_addr"
          >Street is required</small
        >
      </div>
      <div class="col-12 lg:col-6">
        <div class="p-inputgroup mt-2">
          <small
            class="p-inputgroup-addon min-w-min font-semibold general-label-color"
            :style="{ width: '80px' }"
          >
            City
          </small>
          <InputText
            type="text"
            class="text-sm"
            v-model="creditCard.cc_city"
            :disabled="inputDisabled"
          />
        </div>
      </div>
      <div class="col-12 lg:col-6">
        <div class="p-inputgroup mt-2">
          <small
            class="p-inputgroup-addon min-w-min font-semibold general-label-color"
            :style="{ width: '80px' }"
          >
            State
          </small>
          <InputText
            type="text"
            v-model="creditCard.cc_state"
            class="text-sm"
            :disabled="inputDisabled"
          />
        </div>
      </div>
      <div class="col-12 lg:col-6">
        <div class="p-inputgroup mt-2">
          <small
            class="p-inputgroup-addon min-w-min font-semibold general-label-color"
            :style="{ width: '80px' }"
          >
            Zip
          </small>
          <InputText
            type="text"
            v-model="creditCard.cc_zip"
            class="text-sm"
            :disabled="inputDisabled"
          />
        </div>
        <small class="p-error" v-if="submitted && !creditCard.cc_zip"
          >Zip is required</small
        >
      </div>
      <div class="col-12 lg:col-6">
        <div class="p-inputgroup mt-2">
          <small
            class="p-inputgroup-addon min-w-min font-semibold general-label-color"
            :style="{ width: '80px' }"
          >
            Country
          </small>
          <InputText
            type="text"
            v-model="creditCard.cc_country"
            class="text-sm"
            :disabled="inputDisabled"
          />
        </div>
      </div>
    </div>
    <div class="text-center" v-if="this.success && !this.displayIframe">
      <b>
        <i class="pi pi-check-circle mr-2 text-green-500"></i>
        <span class="text-green-500">Credit Card Validation Successful</span>
      </b>
    </div>
    <template v-if="showButton" #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="triggerHideDialog"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        :disabled="
          !this.success ||
          !this.creditCard.cardholder_name ||
          !this.creditCard.cc_addr ||
          !this.creditCard.cc_zip
        "
        @click="save"
      />
    </template>
  </Dialog>
  <Dialog
    v-model:visible="deleteItemDialog"
    :style="{ width: '450px' }"
    header="Confirm Action"
    :modal="true"
    :draggable="false"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span v-if="creditCard">Are you sure you want to delete?</span>
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="deleteItemDialog = false"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-text"
        @click="deleteItem"
      />
    </template>
  </Dialog>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import DataTable from "primevue/datatable";
import Card from "primevue/card";
import Button from "primevue/button";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import iframeResize from "iframe-resizer/js/iframeResizer";
import Utils from "@/utility/utils";

export default defineComponent({
  name: "Payment",
  props: {
    index: Number,
    showAddressInput: Boolean,
    getIframeUrl: String,
    creditCardData: Boolean,
    ccAdrRqrd: Boolean,
    submitted: Boolean,
  },
  components: {
    DataTable,
    Column,
    Card,
    Dialog,
    InputText,
    Button,
  },
  directives: {
    resize: {
      beforeMount: function (el, { value = {} }) {
        el.addEventListener("load", () => iframeResize(value, el));
      },
    },
  },
  mounted() {
    window.addEventListener("message", this.receiveToken);
    this.creditCards = this.creditCardData;
  },
  data() {
    return {
      creditCard: {
        cc_addr: "",
        cc_city: "",
        credit_card_exp: "",
        credit_card_no: "",
        credit_card_id: "",
        cardholder_name: "",
        cc_state: "",
        cc_zip: "",
        cc_country: "",
      },
      creditCardDialog: false,
      deleteItemDialog: false,
      token: "",
      success: false,
      displayIframe: false,
      tokenMessage: "",
      iframeSrc: this.getIframeUrl,
      creditCards: [],
      showButton: true,
      inputDisabled: false,
      iframeKey: 0,
    };
  },
  watch: {
    creditCard: {
      handler(newCreditCard) {
        this.$emit("creditCardChanged", newCreditCard);
      },
      deep: true,
    },
    creditCardData(newVal) {
      this.creditCards = newVal;
    },
    getIframeUrl(newUrl) {
      this.iframeSrc = newUrl;
    },
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
    }),
    handleError(title, message) {
      this.addNotification({
        title: title,
        message: message,
        type: "error",
      });
      this.reloadIframe();
      return;
    },
    checkErrorResponse(token) {
      if (token?.errorMessage && token?.errorMessage !== "") {
        this.handleError("Error", token.errorMessage);
        return;
      }
      if (token?.status === "501" && token?.statusMessage !== "") {
        this.handleError("Error", token.statusMessage);
        return;
      }
    },
    openNew() {
      this.creditCard = {};
      this.creditCardDialog = true;
      this.displayIframe = true;
      this.success = false;
      this.showButton = true;
      this.inputDisabled = false;
    },
    openRow(rowData) {
      this.creditCard = { ...rowData.data };
      this.creditCardDialog = true;
      this.showButton = false;
      this.displayIframe = false;
      this.inputDisabled = true;
    },
    triggerHideDialog() {
      this.creditCardDialog = false;
    },
    save() {
      this.$emit("save-payment", this.creditCard);
      this.creditCardDialog = false;
    },
    deleteItem() {
      this.$emit("delete-item", this.creditCard);
      this.deleteItemDialog = false;
    },
    confirmDeleteItem(creditItem) {
      this.creditCard = creditItem;
      this.deleteItemDialog = true;
    },
    formatCardNumber(card_id) {
      if (card_id != null) return "************" + card_id.substring(12, 16);
    },
    formatExpDate(dateString) {
      return Utils.formatExpDateString(dateString);
    },
    reloadIframe() {
      this.iframeSrc = "";
      this.iframeKey++;
      setTimeout(() => {
        this.iframeSrc = this.getIframeUrl;
      }, 0);
    },
    receiveToken(event) {
      if (event.origin.includes("gateway.total-computing.com")) {
        var token = event.data;
        const newCard = {};

        if (token.success) {
          newCard.credit_card_id = token.message;
          newCard.credit_card_exp = token.expiry;
          newCard.credit_card_no =
            "************" + newCard.credit_card_id.substring(12, 16);
          this.creditCard = newCard;
          this.success = true;
          this.displayIframe = !this.displayIframe;
        } else {
          this.checkErrorResponse(token);
        }
      }
    },
  },
});
</script>

<style scoped>
@media screen and (min-width: 1279px) {
  .response-button {
    display: none !important;
  }
}
</style>
